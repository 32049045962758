import React from "react";
import { Box, FormControl, InputLabel, Button, Grid } from "@material-ui/core";
import imageUrl from "../sections/pic.png";
import { useNavigate } from "react-router-dom";

function AddressVerificationFailed() {
  const navigate = useNavigate();

  const next = async () => {
    navigate("/buyonline", { replace: true });
  };

  const back = async () => {
    navigate("/schoolstudentaddressverification", { replace: true });
  };

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Address Match Failed
        </h>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
          </Grid>
          <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
            The Address that you entered didn't match the address that the
            school has on record for the student previously entered.
            <br />
            <br />
            You may re-enter the address and try the validation again by
            selecting the Re-Enter Details button below.
            <br />
            <br />
            Without a vaild address match to this student you will not be able
            to order a Smartrider.
          </p>
        </Grid>
        <Button
          style={
            window.innerWidth > 775
              ? {
                  backgroundColor: "green",
                  color: "#ffffff",
                  fontSize: 20,
                  marginLeft: "36%",
                  width: "13%",
                }
              : {
                  backgroundColor: "green",
                  color: "#ffffff",
                  marginLeft: "20%",
                  fontSize: "110%",
                }
          }
          onClick={back}
        >
          Re-enter details
        </Button>

        <Button
          style={
            window.innerWidth > 775
              ? {
                  backgroundColor: "red",
                  color: "#ffffff",
                  marginLeft: "2%",
                  fontSize: 20,
                  width: "13%",
                }
              : {
                  backgroundColor: "red",
                  color: "#ffffff",
                  marginLeft: "2%",
                  fontSize: "110%",
                }
          }
          onClick={next}
        >
          Close
        </Button>
        <Grid style={{ marginTop: "15%" }}></Grid>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const listStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "30%",
  paddingRight: "30%",
};
const listStyle1 = {
  fontSize: "140%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

export default AddressVerificationFailed;
