import React, { useState } from "react";
import { Box, FormControl, InputLabel, Button, Grid } from "@material-ui/core";
import Smartriderbanner from "../Smartriderbanner";
import { useNavigate } from "react-router-dom";

function Buyonline() {
  const navigate = useNavigate();
  const SCS = async () => {
    navigate("/termsandconditions", { replace: true });
  };

  return (
    <div className="App">
      <div className="App-content">
        <Smartriderbanner />
        <div
          style={{ marginTop: "-10%", marginLeft: "15%", marginRight: "15%" }}
        >
          <p style={styleObj}>
            Smartrider allows you to travel on the WA public transport network
            without the need to carry cash, funds can be added electronically
            and depending on your school your smartrider could be used as a form
            of ID.
            <br />
            <br />
            With Monitor WA's interfacing technology you can use your Smartrider
            Card to access your schools facilities for example pay for lunches,
            use library resouces and much more....
          </p>
        </div>
        <br />
        <div style={{ alignItems: "center", textAlign: "center" }}>
          <Button style={styleButton} onClick={SCS}>
            Order Your Student SmartRider
          </Button>{" "}
        </div>

        <br />
        <Grid style={{ marginTop: "0.5%" }}></Grid>
      </div>
    </div>
  );
}

const styleButton = {
  color: "#ffffff",
  fontSize: "110%",
  backgroundColor: "green",
  justifyContent: "center",
};

const styleObj = {
  fontSize: "130%",
  color: "#ffffff",
  textAlign: "left",
};

export default Buyonline;
