import React, { useState } from "react";
import "./Popup.css";
import { MenuItem, Select } from "@material-ui/core";
import { useSnackbar } from "notistack";

function Popup(props) {
  const [selectedItem, setSelectedItem] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = async (e) => {
    props.setSelectedItem(e.target.value);
    setSelectedItem(e.target.value);
  };

  const handleClick = async () => {
    if (selectedItem != undefined) {
      props.cartArray.push({
        itemName: props.title,
        itemOption: selectedItem,
        itemCost: props.cost,
        itemID: props.itemID,
      });
      enqueueSnackbar(
        `${props.title} - ${selectedItem} has been added to your order`,
        { variant: "success" }
      );
    } else {
      enqueueSnackbar(
        `You need to select what type of ${props.title} add to your order`,
        { variant: "error" }
      );
    }

    props.setTrigger(false);
  };

  const handleClick1 = async () => {
    props.setTrigger(false);
  };

  return props.trigger ? (
    <div className="popup">
      <div className="popup-inner">
        <button className="ok-btn" onClick={handleClick}>
          OK
        </button>
        <div className="popup-title">{props.title}</div>
        <div className="popup-message">{props.message}</div>
        <button className="cancel-btn" onClick={handleClick1}>
          Cancel
        </button>
        <div>
          <Select
            className="popup-select"
            onChange={handleChange}
            style={{
              color: "black",
              marginRight: "10%",
              marginTop: "1%",
              border: "1%",
              height: "35px",
              width: "85%",
            }}
          >
            {props.options.length > 0 &&
              props.options.map((op) => (
                <MenuItem value={op} key={op}>
                  {op}
                </MenuItem>
              ))}
          </Select>
        </div>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}

export default Popup;
