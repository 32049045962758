import * as React from "react";
import { Container, Button, Grid, useTheme } from "@material-ui/core";
import styled from "styled-components";
import { Logo } from "../images/Logo";
import HomeIcon from "@material-ui/icons/Home";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { black } from "colors";
import MonitorWA from "../images/MonitorWA";
import Color from "color";
import { createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const theme = createTheme();

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#273d78",
    backgroundSize: "cover",
    backgroundRepeat: "repeat",
    width: window.innerWidth,
    heigth: window.innerHeight,
  },
}));

const MakeCall = async () => {
  window.location = `tel: ${+610892404777}`;
};

const SmartRiderHome = async () => {
  window.location.href = "/buyonline";
};

const FAQ = async () => {
  window.open(
    "https://monitorwa.freshdesk.com/support/solutions/folders/44001193954",
    "_blank"
  );
};

export const Header = ({ children }) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const lightGrey = Color(palette.text.primary).fade(0.25).string();
  const grey = Color(palette.text.primary).fade(0.5).string();
  return (
    <main className={classes.content}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={6} style={{ backgroundColor: "white" }}>
          <div>
            <Logo />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} style={{ backgroundColor: "white" }}>
          <Button
            style={window.innerWidth > 700 ? styleButton : styleButton1}
            onClick={SmartRiderHome}
            startIcon={<HomeIcon />}
          >
            SmartRider
          </Button>

          <Button
            style={window.innerWidth > 700 ? styleButton : styleButton1}
            onClick={FAQ}
            startIcon={<QuestionAnswerIcon />}
          >
            FAQ
          </Button>
          <Button
            style={window.innerWidth > 700 ? styleButton : styleButton1}
            startIcon={
              <FontAwesomeIcon icon={faPhoneAlt} size="sm"></FontAwesomeIcon>
            }
            onClick={MakeCall}
          >
            08 9240 4777
          </Button>
        </Grid>
      </Grid>
      {children}
      <hr
        style={{
          color: black,

          height: 1,
        }}
      />
      <div
        style={{
          alignItems: "center",
          textAlign: "center",
          alignContent: "center",
        }}
      >
        <FooterContainer>
          <text
            style={{
              fontSize: "120%",
            }}
          >
            ©
          </text>
          <text style={{ colour: "white" }}>COPYRIGHT 2024 &nbsp; </text>

          <MonitorWA accent={lightGrey} text={grey} />
        </FooterContainer>
      </div>

      <Grid style={{ marginTop: "10%" }}></Grid>
    </main>
  );
};

const styleButton = {
  align: "center",
  color: "#262846",
  fontWeight: "bold",
  fontSize: "120%",
  marginLeft: "5%",
  marginTop: "3%",
  backgroundColor: "white",
};

const styleButton1 = {
  align: "center",
  color: "#262846",
  fontWeight: "bold",
  fontSize: "120%",
  marginLeft: "1%",
  marginTop: "3%",
  backgroundColor: "white",
};

const FooterContainer = styled.footer`
  display: flex;
  width: 100%;
  padding: 2rem 6rem; */
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
  .logo-link {
    height: 20px;
  }
  .monitor-logo {
    height: 100%;
  }
`;
