import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  Grid,
  TextField,
  Container,
  Input,
  createTheme,
} from "@material-ui/core";
import imageUrl from "../sections/pic.png";
import { LibraryContext } from "../../contexts/LibraryContext";
import { DatabaseContext } from "../../contexts/DatabaseContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { useSnackbar } from "notistack";
import DatePicker from "react-date-picker";
import { browserName, browserVersion } from "react-device-detect";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@mui/styles";

const myTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        input: {
          color: "white",
        },
        root: {
          input: {
            "&:-webkit-autofill": {
              WebkitBoxShadow: "#273d78",
              WebkitTextFillColor: "default",
            },
          },
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "#273d78",
    },
  },
}));

function StudentLookUp() {
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  const [loading, setLoading] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [browser, setBrowser] = useState();
  const [browserVersions, setBrowserVersion] = useState();
  const [value, setValue] = useState(new Date());
  const classes = useStyles();
  const navigate = useNavigate();

  const back = async () => {
    navigate("/selectschool", { replace: true });
  };

  const next = async () => {
    var iOrderTypeID = localStorage.getItem("siteOrderTypeID");

    if (values.DateOfBirth == "") {
      values.DateOfBirth = values.YYYY + "-" + values.MM + "-" + values.DD;
    }

    if (values.FirstName && values.SurName && values.DateOfBirth) {
      var DOBOK = false,
        date = new Date(),
        d =
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate();

      const et = moment(values.DateOfBirth);
      const st = moment(date);

      const diffDuration = moment.duration(st.diff(et));

      const diffYrs = diffDuration.years();

      if (diffYrs > 3) {
        values.DateOfBirth = values.DateOfBirth;
        DOBOK = true;
      } else {
        values.DateOfBirth = null;
        window.alert("Minimum age is 4 years to order a Smartrider card");
      }

      if (values.FirstName && values.SurName && values.DateOfBirth) {
        setLoading(true);
        localStorage.setItem("firstName", values.FirstName);
        localStorage.setItem("middleName", values.MiddleName);
        localStorage.setItem("surname", values.SurName);
        localStorage.setItem("DateOfBirth", values.DateOfBirth);

        //Test with DET LookUp
        try {
          const res = await Axios.get(
            `${IDCaptureAPI}/api/smartrider/det/findStudent/?firstname=${values.FirstName}&lastname=${values.SurName}&birthdate=${values.DateOfBirth}`
          );

          if (res.data.data === 0 && values.MiddleName.length > 0) {
            const resFail = await Axios.get(
              `${IDCaptureAPI}/api/smartrider/det/findStudent/?firstname=${values.FirstName} ${values.MiddleName}&lastname=${values.SurName}&birthdate=${values.DateOfBirth}`
            );

            if (resFail.data.data === 1 || iOrderTypeID == 2) {
              setLoading(false);
              localStorage.setItem("YearGroup", "");
              navigate("/studentcontactinformation", { replace: true });
            }

            let MiddleInitiat = values.MiddleName.charAt(0);

            if (resFail.data.data === 0 && values.MiddleName.length > 0) {
              const resFail1 = await Axios.get(
                `${IDCaptureAPI}/api/smartrider/det/findStudent/?firstname=${values.FirstName} ${MiddleInitiat}&lastname=${values.SurName}&birthdate=${values.DateOfBirth}`
              );

              if (resFail1.data.data === 1 || iOrderTypeID == 2) {
                setLoading(false);
                localStorage.setItem("YearGroup", "");
                navigate("/studentcontactinformation", { replace: true });
              }
            }
          }

          if (res.data.data === 1 || iOrderTypeID == 2) {
            setLoading(false);
            localStorage.setItem("YearGroup", "");
            navigate("/studentcontactinformation", { replace: true });
          } else {
            enqueueSnackbar(
              `No Student Data Found with Dept. of Education (Please contact your school)`,
              {
                variant: "error",
                autoHideDuration: 6000,
                style: { fontSize: "15px" },
              }
            );
          }
        } catch (err) {
          setLoading(false);
          console.log(err);
          enqueueSnackbar(err.response.data.error, {
            variant: "error",
            autoHideDuration: 6000,
            style: { fontSize: "15px" },
          });
        }
      }
    } else {
      handleSubmit();
    }
  };

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setValues,
    setDate,
    handleSubmit,
    resetForm,
    onChange,
  } = useFormik({
    initialValues: {
      FirstName: "",
      MiddleName: "",
      SurName: "",
      DateOfBirth: "",
      DD: "",
      MM: "",
      YYYY: "",
    },
    validationSchema: Yup.object({
      FirstName: Yup.string().required("Please Enter your First Name"),
      MiddleName: Yup.string().nullable(),
      SurName: Yup.string().required("Please Enter your Surname"),
      DateOfBirth: Yup.string().required("Please Enter your date of birth"),
      DD: Yup.string().required("Please Enter Date"),
      MM: Yup.string().required("Please Enter Month"),
      YYYY: Yup.string().required("Please Enter Year"),
    }),
  });

  useEffect(() => {
    setBrowser(`${browserName}`);
    setBrowserVersion(`${browserVersion}`);
    setTimeout(() => {
      localStorage.removeItem("siteName");
      localStorage.removeItem("siteCode");
      localStorage.removeItem("siteOrderTypeID");
      window.location.href = "/buyonline";
    }, 7200000);
  }, []);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Student Details
        </h>
        <br />
        <form>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}></Grid>
            <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
              Please enter the students details below, please ensure that the
              details entered are as per their school student enrollment. <br />
              <br />
              These details will be used to verify the student enrollment with
              the Department of Education.
            </p>
            {/* </Grid> */}
            <br />
            {/* <Container> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={
                window.innerWidth > 775
                  ? { marginLeft: "35%", marginRight: "35%" }
                  : {
                      marginLeft: "10%",
                      marginRight: "10%",
                    }
              }
            >
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  id="FirstName"
                  name="FirstName"
                  label="First Name"
                  value={values.FirstName}
                  className={classes.root}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  variant="outlined"
                  error={touched.FirstName && errors.FirstName}
                  helperText={
                    errors.FirstName && touched.FirstName && errors.FirstName
                  }
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  style={{
                    myTheme,
                    width: "100%",
                  }}
                  required
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="MiddleName"
                  name="MiddleName"
                  label="Middle Name"
                  value={values.MiddleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.MiddleName && errors.MiddleName}
                  helperText={
                    errors.MiddleName && touched.MiddleName && errors.MiddleName
                  }
                  //InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  style={{
                    background: "#273d78",
                    color: "#273d78",
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="SurName"
                  name="SurName"
                  label="Surname"
                  value={values.SurName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.SurName && errors.SurName}
                  helperText={
                    errors.SurName && touched.SurName && errors.SurName
                  }
                  //InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  required
                  style={{
                    background: "#273d78",
                    color: "#273d78",
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              {browser == "Safari" ? (
                <div>
                  <h>Date of Birth</h>
                  <Grid container spacing={2}>
                    <Grid item xs={2} md={2}>
                      <TextField
                        name="DD"
                        label="DD"
                        value={values.DD}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth={true}
                        inputProps={{ maxLength: "2" }}
                      ></TextField>
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <TextField
                        name="MM"
                        label="MM"
                        value={values.MM}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth={true}
                        inputProps={{ maxLength: "2" }}
                      ></TextField>
                    </Grid>
                    <Grid item xs={2} md={3}>
                      <TextField
                        name="YYYY"
                        label="YYYY"
                        value={values.YYYY}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth={true}
                        inputProps={{ maxLength: "4" }}
                      ></TextField>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  padding={{ paddingTop: "20px" }}
                >
                  <TextField
                    name="DateOfBirth"
                    type="Date"
                    label="Date Of Birth"
                    value={values.DateOfBirth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth={true}
                    error={touched.DateOfBirth && errors.DateOfBirth}
                    helperText={
                      errors.DateOfBirth &&
                      touched.DateOfBirth &&
                      errors.DateOfBirth
                    }
                    InputLabelProps={{ shrink: true }}
                  ></TextField>
                </Grid>
              )}
            </Grid>
          </Grid>
          <br />

          {loading > 0 ? (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}></Grid>
              <Grid item xs={12} sm={12} md={12}>
                <p style={messageStyle}>
                  Checking Student Data, Please Wait...
                </p>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          <Button
            style={
              window.innerWidth > 775
                ? {
                    backgroundColor: "#D3212D",
                    color: "#ffffff",
                    marginLeft: "34%",
                    fontSize: 20,
                    width: "15%",
                  }
                : {
                    backgroundColor: "#D3212D",
                    color: "#ffffff",
                    marginLeft: "30%",
                    fontSize: "110%",
                  }
            }
            onClick={back}
          >
            Back
          </Button>

          <Button
            style={
              window.innerWidth > 775
                ? {
                    backgroundColor: "green",
                    color: "#ffffff",
                    marginLeft: "2%",
                    fontSize: 20,
                    width: "15%",
                  }
                : {
                    backgroundColor: "green",
                    color: "#ffffff",
                    marginLeft: "2%",
                    fontSize: "110%",
                  }
            }
            onClick={next}
          >
            Continue
          </Button>
          {/* </Container> */}
        </form>
        <Grid style={{ marginTop: "2%" }}></Grid>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const listStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "30%",
  paddingRight: "30%",
};

const listStyle1 = {
  fontSize: "140%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const messageStyle = {
  fontSize: 18,
  color: "#ffffff",
  textAlign: "centre",
  alignItems: "center",
  textAlign: "center",
};

export default StudentLookUp;
