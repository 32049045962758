import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { red } from "@material-ui/core/colors";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    // transition: theme.transitions.create("transform", {
    //   duration: theme.transitions.duration.shortest,
    // }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const MWACard = (props) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  MWACard.defaultProps = {
    cardColor: "#FFFFFF",
    options: [],
    cost: "0",
  };

  return (
    <>
      <Card className={classes.root}>
        <CardHeader title={props.title} subheader={props.subtitle} />
        <CardMedia
          className={classes.media}
          image={props.image}
          title={props.title}
        />
        <CardContent>
          <Typography variant="body2" color="primary" component="p">
            {props.textBody}
          </Typography>
        </CardContent>

        <CardActions disableSpacing>
          {Number(props.cost) > 0 ? (
            <Tooltip title={`Purchase ${props.title}`}>
              <Button
                color="primary"
                startIcon={
                  <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>
                }
                onClick={() =>
                  props.buyClick(
                    props.title,
                    props.cost,
                    props.itemID,
                    props.options
                  )
                }
              >
                Purchase
              </Button>
            </Tooltip>
          ) : (
            ""
          )}

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>{props.expandedBody}</Typography>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default MWACard;
