import React, { useState, useEffect, useContext } from "react";
import { Grid, Button } from "@material-ui/core";
import Axios from "axios";
import { DatabaseContext } from "../../contexts/DatabaseContext";

function PurchaseComplete() {
  const [purchaseOK, setPurchaseOK] = useState(false);
  const { IDCaptureAPI } = useContext(DatabaseContext);
  const [declineMessage, showDeclineMessage] = useState(false);
  const [WarnMessage, setWarnMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const onlineStore = async () => {
    window.open("https://www.monitorwa.com.au/product-category/accessories/");
  };

  useEffect(() => {
    localStorage.removeItem("firstName");
    localStorage.removeItem("middleName");
    localStorage.removeItem("surname");
    localStorage.removeItem("DateOfBirth");
    localStorage.removeItem("siteName");
    localStorage.removeItem("phone");
    localStorage.removeItem("ResStreet");
    localStorage.removeItem("ResSuburb");
    localStorage.removeItem("ResState");
    localStorage.removeItem("ResPostcode");
    localStorage.removeItem("street");
    localStorage.removeItem("suburb");
    localStorage.removeItem("state");
    localStorage.removeItem("postcode");
    localStorage.removeItem("cartItems");
    localStorage.removeItem("ExternalID");
    localStorage.removeItem("YearGroup");

    setTimeout(() => {
      localStorage.removeItem("siteCode");
      localStorage.removeItem("siteOrderTypeID");
      window.location.href = "/buyonline";
    }, 7200000);
  }, [IDCaptureAPI]);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Smartrider Order Completed
        </h>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p style={window.innerWidth > 775 ? listStyleY : listStyleY1}>
              Your smartrider is now completed, your smartrider will be printed
              and dispatched to your school or the delivery address (conditions
              apply) within the next 2 business days.
              <p style={window.innerWidth > 775 ? listStyleY12 : listStyleY2}>
                You may close this all Monitor WA window(s)/tabs.
              </p>
            </p>
          </Grid>

          <Grid item xs={12} sm={12} md={3}></Grid>

          <Grid item xs={12} sm={12} md={3}></Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p style={window.innerWidth > 775 ? listStyle : listStyle1}>
              For all your Smartrider accessories or other products provided by
              Monitor WA please visit our online store
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={3}></Grid>

          <Grid item xs={12} sm={12} md={4}></Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Button
              style={
                window.innerWidth > 775
                  ? {
                      backgroundColor: "green",
                      color: "#ffffff",
                      //marginLeft: '5%',
                      fontSize: 20,
                      width: "100%",
                    }
                  : {
                      backgroundColor: "green",
                      color: "#ffffff",
                      marginLeft: "25%",
                      fontSize: "110%",
                    }
              }
              onClick={onlineStore}
            >
              Monitor WA Online Store
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={4}></Grid>
        </Grid>
        <Grid style={{ marginTop: "15%" }}></Grid>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const listStyle = {
  fontSize: 20,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyle1 = {
  fontSize: "150%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY = {
  fontSize: 20,
  color: "yellow",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY1 = {
  fontSize: "150%",
  color: "yellow",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY12 = {
  fontSize: "150%",
  color: "red",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const listStyleY2 = {
  fontSize: "150%",
  color: "red",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

export default PurchaseComplete;
