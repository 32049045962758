import React from 'react';
import styled from 'styled-components';

const MonitorWA = ({ accent, text }) => {
  return (
    <a className='logo-link' href='https://www.monitorwa.com.au/'>
      <Svg
        className='monitor-logo'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        x='0'
        y='0'
        viewBox='0 0 1398.8 219.3'
        xmlSpace='preserve'
        textColour={text}
        accentColour={accent}
        hoverText='#FFFFFF'
        hoverAccent='#EF4646'
      >
        <g className='text'>
          <path
            d='M82.2,201.5l1.2,1.9l-7.7,12.9c-1.5,2.5-4.5,3.4-7.1,2.8v0.2h-0.8c-2.2,0-4.3-1-5.5-3L0,112.4
		c-1-1.7-1-3.7,0-5.4L62.3,3c1.2-2,3.4-3,5.5-3h2.3c2.2,0,4.4,1,5.5,3l7.7,12.9l-1.2,2l-5.2,8.8l-48,80.3c-1,1.7-1,3.7,0,5.4
		L77,192.7L82.2,201.5z'
          />
          <path
            d='M86.2,112.4l25.9,43.2l-14.4,24.1l-40.3-67.3c-1-1.7-1-3.7,0-5.4l40.3-67.3l14.5,24.1L86.3,107
		C85.2,108.7,85.2,110.7,86.2,112.4z'
          />
        </g>
        <g className='accent'>
          <path
            d='M224.3,112.4l-62.3,104c-1.2,2-3.4,3-5.5,3h-28.6c2.2,0,4.3-1,5.5-3l7.5-12.6l54.8-91.4c1-1.7,1-3.7,0-5.4
			l-54.8-91.4L133.3,3c-1.2-1.9-3.3-3-5.5-3h28.5c2.1,0,4.3,1,5.5,3l62.3,104C225.2,108.7,225.2,110.7,224.3,112.4z'
          />
          <path
            d='M167.6,109c0.1,0.5,0.1,0.9,0,1.4l-0.1,0.5c-0.1,0.2-0.1,0.3-0.2,0.5c0,0.1-0.1,0.2-0.1,0.3s-0.1,0.1-0.1,0.2
			c-0.1,0.2-0.2,0.3-0.3,0.5l-40.3,67.3l-14.4,24.1l-7.5,12.6c-0.7,1.2-1.9,2.1-3.1,2.5c-0.5,0.2-1,0.3-1.6,0.4
			c-0.3,0-0.5,0.1-0.8,0.1c-0.5,0-1.1-0.1-1.6-0.2v0.2H68.6v-0.2c2.6,0.6,5.6-0.3,7.1-2.8l7.7-12.9l1.2-2l5.3-8.8l7.8-13l14.4-24.1
			l25.9-43.2c1-1.7,1-3.7,0-5.4l-25.9-43.2L97.6,39.7l-7.8-13l-5.3-8.8L83.3,16L75.6,3.1c-1.2-1.9-3.4-3-5.5-3H99
			c1.6,0,3.2,0.6,4.4,1.7l0.6,0.6c0.2,0.2,0.3,0.4,0.5,0.7l7.5,12.6l1.4,2.3l5.3,8.8l7.8,13l40.3,67.3c0.1,0.2,0.2,0.3,0.3,0.5
			c0.1,0.3,0.3,0.7,0.4,1C167.5,108.7,167.5,108.9,167.6,109L167.6,109z'
          />
        </g>
        <path
          className='text'
          d='M374,163.3V92.8l-34.1,70.5h-19.6l-33.9-70.5v70.5H267V55.6h23.1l40.2,86.1l40.4-86.1h22.7v107.7H374z
	 M514.7,90.7c0-5.3-0.9-8.9-2.8-10.9c-1.9-1.9-5.2-2.9-9.9-2.9h-56.6c-4.7,0-8,1-9.9,2.9s-2.8,5.6-2.8,10.9v37.9
	c0,2.7,0.2,4.9,0.6,6.6c0.4,1.7,1.1,3.1,2.1,4.1c1,1.1,2.3,1.8,3.9,2.2s3.6,0.6,6,0.6h56.6c4.8,0,8.1-0.9,9.9-2.8
	c1.8-1.9,2.7-5.5,2.7-10.8V90.7L514.7,90.7z M445.4,163.3c-6.6,0-12-0.6-16.3-1.7c-4.3-1.2-7.7-3.1-10.3-5.8
	c-2.6-2.7-4.4-6.3-5.5-10.8c-1-4.5-1.6-10-1.6-16.6V90.5c0-6.6,0.5-12.1,1.6-16.6c1-4.5,2.9-8.1,5.5-10.8c2.6-2.7,6.1-4.6,10.3-5.8
	c4.3-1.1,9.7-1.7,16.3-1.7h56.8c6.4,0,11.7,0.6,16,1.7s7.8,3.1,10.4,5.8c2.6,2.7,4.5,6.3,5.6,10.8s1.6,10,1.6,16.6v37.9
	c0,6.6-0.5,12.1-1.6,16.6s-2.9,8.1-5.6,10.8c-2.6,2.7-6.1,4.6-10.4,5.8c-4.3,1.1-9.7,1.7-16,1.7L445.4,163.3L445.4,163.3z
	 M650.4,163.3l-75.9-79.4v79.4h-20.2V55.6H577l73.8,77.9V55.6h20.4v107.7H650.4z M690.1,55.6h21.1v107.7h-21.1V55.6z M763.8,163.3
	V76.9h-39.3V55.6h99.7v21.3h-39.3v86.4H763.8z M939.8,90.7c0-5.3-0.9-8.9-2.8-10.9c-1.9-1.9-5.2-2.9-9.9-2.9h-56.6
	c-4.7,0-8,1-9.9,2.9s-2.8,5.6-2.8,10.9v37.9c0,2.7,0.2,4.9,0.6,6.6c0.4,1.7,1.1,3.1,2.1,4.1c1,1.1,2.3,1.8,3.9,2.2
	c1.6,0.4,3.6,0.6,6,0.6H927c4.8,0,8.1-0.9,9.9-2.8c1.8-1.9,2.7-5.5,2.7-10.8V90.7L939.8,90.7z M870.5,163.3c-6.6,0-12-0.6-16.3-1.7
	c-4.3-1.2-7.7-3.1-10.3-5.8s-4.4-6.3-5.5-10.8c-1-4.5-1.6-10-1.6-16.6V90.5c0-6.6,0.5-12.1,1.6-16.6c1-4.5,2.9-8.1,5.5-10.8
	c2.6-2.7,6.1-4.6,10.3-5.8c4.3-1.1,9.7-1.7,16.3-1.7h56.8c6.4,0,11.7,0.6,16,1.7s7.8,3.1,10.4,5.8c2.6,2.7,4.5,6.3,5.6,10.8
	s1.6,10,1.6,16.6v37.9c0,6.6-0.5,12.1-1.6,16.6s-2.9,8.1-5.6,10.8c-2.6,2.7-6.1,4.6-10.4,5.8c-4.3,1.1-9.7,1.7-16,1.7L870.5,163.3
	L870.5,163.3z M1077.2,92.5c0-5.2-0.6-9.1-1.8-11.7c-1.2-2.6-4.1-3.8-8.7-3.8h-66v33.4h66c3.8,0,6.4-0.9,8.1-2.8
	c1.6-1.9,2.4-5.2,2.4-9.9V92.5z M1071.9,163.3l-37.2-35.2h-34v35.2h-21.3V55.6h87.9c5.7,0,10.6,0.6,14.5,1.8
	c3.9,1.2,7.1,3.1,9.5,5.7s4.2,6.1,5.2,10.3c1.1,4.3,1.6,9.4,1.6,15.5v6.6c0,6.6-0.6,11.9-2,16.1c-1.3,4.2-3.4,7.5-6.3,9.9
	s-6.7,4-11.3,4.9c-4.6,0.9-10.2,1.3-16.9,1.3l39.9,35.5h-29.6V163.3z'
        />
        <g className='accent'>
          <path
            d='M1222.2,163.3l-28.8-80.2l-29.6,80.2h-22.7l-39.6-107.7h24.1l27.5,82.6l29.6-82.6h21.3l28.9,82.8l28.4-82.8
		h24.1L1245,163.3H1222.2z M1328.9,76.7l-24.4,44.6h48.8C1353.3,121.3,1328.9,76.7,1328.9,76.7z M1374.7,163.3l-13.5-25h-64.9
		l-13.5,25h-24.1l59-107.7h22.1l59,107.7H1374.7z'
          />
        </g>
      </Svg>
    </a>
  );
};

const Svg = styled.svg`
  transition: all ease-in-out 300ms;
  .text {
    fill: ${(props) => props.textColour};
  }
  .accent {
    fill: ${(props) => props.accentColour};
  }
  &:hover {
    .text {
      fill: ${(props) => props.hoverText};
    }
    .accent {
      fill: ${(props) => props.hoverAccent};
    }
  }
`;

export default MonitorWA;
