import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import Dashboard from "./pages/Dashboard";
import Buyonline from "./pages/sections/Buyonline";
import TermsAndConditions from "./pages/sections/TermsAndConditions";
import SelectSchool from "./pages/sections/SelectSchool";
import StudentLookUp from "./pages/sections/StudentLookUp";
import StudentContactInformation from "./pages/sections/StudentContactInformation";
import ConfirmDetails from "./pages/sections/ConfirmDetails";
import Accessories from "./pages/sections/Accessories";
import Payment from "./pages/sections/Payment";
import PurchaseComplete from "./pages/sections/PurchaseComplete";
import SchoolStudentLookUp from "./pages/sections/SchoolStudentLookUp";
import SchoolStudentAddressVerification from "./pages/sections/SchoolStudentAddressVerification";
import AddressVerificationFailed from "./pages/sections/AddressVerificationFailed";
import SchoolContactDetails from "./pages/sections/SchoolContactDetails";
import FreeCardComplete from "./pages/sections/FreeCardComplete";
import PageNotFound from "./pages/sections/404Page";

import { Header } from "../src/components/Header";

import { DatabaseProvider } from "../src/contexts/DatabaseContext";
import { ThemeProvider } from "./contexts/ThemeContext";

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={2200}
      >
        <BrowserRouter>
          <DatabaseProvider>
            <Header>
              <Routes>
                {/* <Route path="/" element={<Dashboard />} /> */}
                <Route path="/buyonline" element={<Buyonline />} />
                <Route
                  path="/termsandconditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/selectschool" element={<SelectSchool />} />
                <Route path="/studentlookup" element={<StudentLookUp />} />
                <Route
                  path="/studentcontactinformation"
                  element={<StudentContactInformation />}
                />
                <Route path="/confirmdetails" element={<ConfirmDetails />} />
                <Route path="/accessories" element={<Accessories />} />
                <Route path="/payment" element={<Payment />} />
                <Route
                  path="/purchasecomplete"
                  element={<PurchaseComplete />}
                />
                <Route
                  path="/schoolstudentlookup"
                  element={<SchoolStudentLookUp />}
                />
                <Route
                  path="/schoolstudentaddressverification"
                  element={<SchoolStudentAddressVerification />}
                />
                <Route
                  path="/addressverificationfailed"
                  element={<AddressVerificationFailed />}
                />

                <Route
                  path="/schoolcontactdetails"
                  element={<SchoolContactDetails />}
                />
                <Route
                  path="*"
                  element={<Navigate to="/buyonline" replace />}
                />
                <Route
                  path="/freecardcomplete"
                  element={<FreeCardComplete />}
                />
              </Routes>
            </Header>
          </DatabaseProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
