import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Container,
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

function TermsAndConditions() {
  const navigate = useNavigate();
  const [continueOK, setContinueOK] = useState(false);

  const back = async () => {
    navigate("/buyonline", { replace: true });
  };

  const next = () => {
    if (values.AgreeToTC) {
      navigate("/selectschool", { replace: true });
    } else {
      alert("Please agree with term and conditions to continue");
    }
  };

  const HandleCheckChange = () => {
    if (!values.AgreeToTC) {
      values.AgreeToTC = true;
      setContinueOK(true);
    } else {
      values.AgreeToTC = false;
      setContinueOK(false);
    }
  };

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setValues,
    resetForm,
    handleSubmit,
    handleEdit,
    handleDelete,
  } = useFormik({
    initialValues: {
      AgreeToTC: "",
    },
    validationSchema: Yup.object({
      AgreeToTC: Yup.string(),
    }),
  });

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/buyonline";
    }, 7200000);
  }, []);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Term & Conditions
        </h>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12}>
            <p style={window.innerWidth > 775 ? styleObj : styleObj1}>
              You may only purchase a Student Concession card, if you meet/agree
              with all of the following criteria:
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <ul style={window.innerWidth > 775 ? styleObj : styleObj1}>
              <li>
                You currently attend/or are the legal guardian of a student that
                currently attends, a WA primary or secondary school. (N.B.
                Tertiary students can't order cards from this portal).
              </li>
              <br />
              <li>
                Your school is a Department of Education (DET) school or the
                school registered to participate in the online ordering system.
                If your school is not available from the list of available
                schools, then please contact your school and request that they
                apply.
              </li>
              <br />
              <li>
                All online card orders require a payment of
                <b> $5.00 by credit card payment (VISA or MasterCard only). </b>
                No other payment methods are accepted and credit card must be
                with an Australian Financial Institution.{" "}
              </li>
              <br />
              <li>
                This payment is for the Smartrider Card and postage and is not
                added to the SmartRider Card for travel credit. Please be aware
                that any replacement card will automatically disable your
                previous cards and funds on your previous card may be
                transferred automatically.
              </li>
              <br />
              <li>
                For Application Conditions and Our Privacy Statement please
                refer to the Monitor WA FAQ above.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={4} md={4}></Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormControlLabel
              style={window.innerWidth > 775 ? FormStyle : FormStyle1}
              label={
                <Typography variant="body1" color="yellow">
                  <b>I agree to the Terms & Condtions as outlined above</b>
                </Typography>
              }
              control={
                <Checkbox
                  style={styleCheckBox}
                  name="AgreeTCS"
                  check={values.AgreeToTC}
                  onChange={HandleCheckChange}
                />
              }
            />
          </Grid>

          <Grid item xs={12} sm={4} md={4}></Grid>
          <br />
          <Grid item xs={12} sm={12} md={12}>
            <Button
              style={window.innerWidth > 775 ? buttonStyle : buttonStyle1}
              onClick={back}
            >
              Back
            </Button>

            <Button
              style={window.innerWidth > 775 ? buttonStyleCon : buttonStyleCon1}
              onClick={next}
              disabled={!continueOK}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "2%" }}></Grid>
      </div>
    </div>
  );
}

const styleObj1 = {
  fontSize: "150%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "10%",
  paddingRight: "10%",
};

const styleCheckBox1 = {
  fontSize: "130%",
  color: "#ffffff",
};

const listStyle1 = {
  fontSize: "130%",
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "15%",
  paddingRight: "15%",
};

const styleHeader1 = {
  fontSize: "220%",
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "1%",
  fontWeight: "bold",
  display: "flex",
};

const styleObj = {
  fontSize: 20,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "20%",
  paddingRight: "20%",
};

const styleCheckBox = {
  fontSize: 16,
  color: "yellow",
  textAlign: "left",
  width: "20px",
  marginRight: "20px",
};

const listStyle = {
  fontSize: 16,
  color: "#ffffff",
  textAlign: "left",
  paddingLeft: "30%",
  paddingRight: "30%",
};

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const FormStyle1 = {
  marginLeft: "15%",
  marginRight: "5%",
};
const FormStyle = {
  marginLeft: "0%",
  marginRight: "0%",
};
const buttonStyle1 = {
  backgroundColor: "#D3212D",
  color: "#ffffff",
  marginLeft: "15%",
  fontSize: "110%",
};
const buttonStyle = {
  backgroundColor: "#D3212D",
  color: "#ffffff",
  marginLeft: "5%",
  fontSize: 20,
  width: "20%",
};
const buttonStyleCon = {
  backgroundColor: "green",
  color: "#ffffff",
  marginLeft: "5%",
  fontSize: 20,
  width: "20%",
};
const buttonStyleCon1 = {
  backgroundColor: "green",
  color: "#ffffff",
  marginLeft: "15%",
  fontSize: "110%",
};

export default TermsAndConditions;
