import React from "react";
import Color from "color";
import moment from "moment";
import localisation from "moment/locale/en-au";
import {
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
} from "styled-components";
import {
  ThemeProvider as MaterialThemeProvider,
  createTheme,
  responsiveFontSizes,
  CssBaseline,
} from "@material-ui/core";

import { StyledEngineProvider } from "@mui/styled-engine";
// import { MuiPickersUtilsProvider } from "@mui/pickers";
import MomentUtils from "@date-io/moment";
import image from "../pages/sections/pic.png";

// Set locale for moment date conversion
moment.updateLocale("en-au", localisation);

const GlobalStyle = createGlobalStyle`
  *::-webkit-scrollbar {
    width: 8px;
  }
  *::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: ${(props) => Color(props.accent).fade(0.7).string()};
  }
  *::-webkit-scrollbar-track {
    box-shadow: none;
  }
  *::selection {
    background: ${(props) => props.accent};
    color: ${(props) => props.background}
  }
  
  body {
    scrollbar-face-color: ${(props) => props.accent};
    scrollbar-track-color: transparent;
  }

`;

export const ThemeProvider = ({ children }) => {
  const colours = {
    background: "#FFFFFF",
    backgroundDark: "#3b3b50",
    accent: "#FCC133",
    special: "#ba000d",
    success: "#ffffff",
    error: "#FCC133",
    text: "#ffffff",
    menu: "#ba000d",
    danger: "#FF0000",
    txtBlack: "#000000",
  };

  const materialTheme = createTheme({
    palette: {
      type: "dark",
      background: {
        default: colours.background,
        paper: colours.backgroundDark,
        backgroundSize: "cover",
        backgroundRepeat: "repeat",
        backgroundColor: "#273d78",
      },
      primary: {
        main: colours.background,
        dark: colours.backgroundDark,
        contrastText: colours.text,
      },
      secondary: {
        main: colours.accent,
        contrastText: colours.background,
      },
      menuHeader: {
        main: colours.menu,
        contrastText: colours.background,
      },
      info: {
        main: colours.special,
      },
      text: {
        primary: colours.text,
        secondary: Color(colours.text).fade(0.45).string(),
        disabled: Color(colours.text).fade(0.75).string(),
      },
      date: {
        primary: colours.date,
      },
      success: {
        main: colours.success,
      },
      error: {
        main: colours.error,
      },
      warning: {
        main: colours.danger,
      },
    },
    overrides: {
      MuiTextField: {
        root: {
          "& input:valid + fieldset": {
            borderColor: colours.success,
          },
          "& input:invalid + fieldset": {
            borderColor: colours.error,
          },
        },
      },
      MuiInputLabel: {
        asterisk: {
          display: "none",
        },
      },

      MuiSelect: {
        root: {
          backgroundColor: "rgb(39, 61, 120)",
          borderColor: "#000000",
          "& input:valid + fieldset": {
            borderColor: colours.success,
          },
          "& input:invalid + fieldset": {
            borderColor: "#000000",
          },
        },
      },
    },
    props: {
      MuiButton: {
        //variant: 'outlined',
        color: "warning",
      },
      MuiTextField: {
        fullWidth: true,
        color: "secondary",
        variant: "outlined",
        margin: "normal",
        size: "small",
      },
      MuiSelect: {
        fullWidth: true,
        color: "rgb(39, 61, 120)",
      },
      MuiFormControl: {
        fullWidth: true,
        variant: "outlined",
        color: "secondary",
        margin: "normal",
        size: "small",
      },
      MuiPaper: {
        variant: "outlined",
      },
      MuiTooltip: {
        placement: "top",
        arrow: true,
      },
    },
  });

  const responsiveTheme = responsiveFontSizes(materialTheme);

  return (
    <MaterialThemeProvider theme={responsiveTheme}>
      <StyledThemeProvider theme={responsiveTheme}>
        <StyledEngineProvider injectFirst>
          {/* <MuiPickersUtilsProvider utils={MomentUtils}> */}
          <CssBaseline />
          <GlobalStyle
            background={colours.background}
            accent={colours.accent}
          />
          {children}
          {/* </MuiPickersUtilsProvider> */}
        </StyledEngineProvider>
      </StyledThemeProvider>
    </MaterialThemeProvider>
  );
};
