import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Button,
  Checkbox,
  MenuItem,
  Select,
  Grid,
  Container,
  Input,
} from "@material-ui/core";
import imageUrl from "../sections/pic.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function SchoolContactDetails() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  var phoneRegEx =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setValues,
    setDate,
    handleSubmit,
    resetForm,
    onChange,
    handleClick,
  } = useFormik({
    initialValues: {
      Phone: "",
      Email: "",
      ParentGuardian: "",
      PostalStreet: "",
      PostalSuburb: "",
      PostalState: "WA",
      PostalPostcode: "",
      showPostalAddress: "",
    },
    validationSchema: Yup.object({
      Phone: Yup.string()
        .required("Please Enter your phone number")
        .matches(phoneRegEx, "Please enter a valid phone number"),
      Email: Yup.string().email().required("Please Enter your email address"),
      ParentGuardian: Yup.string().nullable(),
      showPostalAddress: Yup.boolean(),
      PostalStreet: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string().required("Please Enter your street name");
        },
      }),
      PostalSuburb: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string().required("Please Enter your Suburb name");
        },
      }),
      PostalState: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string().required("Please choose your street ");
        },
      }),
      PostalPostcode: Yup.string().when("showPostalAddress", {
        is: true,
        then: () => {
          Yup.string().required().max(4, "Postcode can only be 4 digits");
        },
      }),
    }),
  });

  const showPostal = () => {
    if (!values.showPostalAddress) {
      values.showPostaAddress = true && {
        PostalSuburb: Yup.string().required(),
      };
    } else {
      values.showPostaAddress = false;
    }
  };

  const back = async () => {
    navigate("/schoolstudentaddressverification", { replace: true });
  };

  const next = async () => {
    values.Email = values.Email.trim();
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (values.showPostalAddress === true) {
      if (
        values.Phone &&
        re.test(values.Email) &&
        values.PostalStreet &&
        values.PostalSuburb &&
        values.PostalState &&
        values.PostalPostcode
      ) {
        localStorage.setItem("phone", values.Phone);
        localStorage.setItem("email", values.Email);
        localStorage.setItem("parent", values.ParentGuardian);
        localStorage.setItem("street", values.PostalStreet);
        localStorage.setItem("suburb", values.PostalSuburb);
        localStorage.setItem("state", values.PostalState);
        localStorage.setItem("postcode", values.PostalPostcode);

        navigate("/confirmdetails", { replace: true });
      } else {
        enqueueSnackbar(`Please ensure all required data is entered`, {
          variant: "error",
        });
        handleSubmit();
      }
    }
    if (values.showPostalAddress !== true) {
      if (values.Phone && re.test(values.Email)) {
        localStorage.setItem("phone", values.Phone);
        localStorage.setItem("email", values.Email);
        localStorage.setItem("parent", values.ParentGuardian);
        localStorage.setItem("street", values.PostalStreet);
        localStorage.setItem("suburb", values.PostalSuburb);
        localStorage.setItem("state", values.PostalState);
        localStorage.setItem("postcode", values.PostalPostcode);
        window.location.href = `/confirmdetails`;
      } else {
        enqueueSnackbar(`Please ensure all required data is entered`, {
          variant: "error",
        });
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      localStorage.removeItem("siteName");
      localStorage.removeItem("siteCode");
      localStorage.removeItem("siteOrderTypeID");
      localStorage.removeItem("firstName");
      localStorage.removeItem("middleName");
      localStorage.removeItem("surname");
      localStorage.removeItem("DateOfBirth");
      window.location.href = "/buyonline";
    }, 7200000);
  }, []);

  return (
    <div className="App">
      <div className="App-content">
        <h style={window.innerWidth > 775 ? styleHeader : styleHeader1}>
          Contact & Delivery Information
        </h>
        <br />
        <form>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={
                window.innerWidth > 775
                  ? { marginLeft: "35%", marginRight: "35%" }
                  : {
                      marginLeft: "10%",
                      marginRight: "10%",
                    }
              }
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="ParentGuardian"
                  name="ParentGuardian"
                  label="Parent/Guardian"
                  value={values.ParentGuardian}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.ParentGuardian && errors.ParentGuardian}
                  helperText={
                    errors.ParentGuardian &&
                    touched.ParentGuardian &&
                    errors.ParentGuardian
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { textTransform: "capitalize" } }}
                  style={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="Phone"
                  name="Phone"
                  label="Phone"
                  value={values.Phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Phone && errors.Phone}
                  helperText={errors.Phone && touched.Phone && errors.Phone}
                  InputLabelProps={{ shrink: true }}
                  style={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                padding={{ paddingTop: "20px" }}
              >
                <TextField
                  id="Email"
                  name="Email"
                  label="Email"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.Email && errors.Email}
                  helperText={errors.Email && touched.Email && errors.Email}
                  InputLabelProps={{ shrink: true }}
                  style={{
                    width: "100%",
                  }}
                ></TextField>
              </Grid>
              {/* </Grid>

            <Grid item xs={12} sm={12} md={4}></Grid> */}
              <FormControlLabel
                label="Post to Different Address"
                control={
                  <Checkbox
                    style={styleCheckBox}
                    name="showPostalAddress"
                    check={values.showPostalAddress}
                    onChange={handleChange}
                  />
                }
              />
              {values.showPostalAddress === true ? (
                <Grid container spacing={1}>
                  {/* <Grid item xs={12} sm={12} md={4}></Grid>
                <Grid item xs={12} sm={12} md={4}> */}
                  <h
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "bold",
                      marginLeft: "5%",
                    }}
                  >
                    Contact Details
                  </h>
                  <br />
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    padding={{ paddingTop: "20px" }}
                  >
                    <TextField
                      id="PostalStreet"
                      name="PostalStreet"
                      label="Street"
                      value={values.PostalStreet}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.PostalStreet && errors.PostalStreet}
                      helperText={
                        errors.PostalStreet &&
                        touched.PostalStreet &&
                        errors.PostalStreet
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      required
                      style={{
                        width: "100%",
                      }}
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    padding={{ paddingTop: "20px" }}
                  >
                    <TextField
                      id="PostalSuburb"
                      name="PostalSuburb"
                      label="Suburb"
                      value={values.PostalSuburb}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.PostalSuburb && errors.PostalSuburb}
                      helperText={
                        errors.PostalSuburb &&
                        touched.PostalSuburb &&
                        errors.PostalSuburb
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      required
                      style={{
                        width: "100%",
                      }}
                    ></TextField>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    padding={{ paddingTop: "20px" }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="PostalState"
                          name="PostalState"
                          label="State"
                          value={values.PostalState}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.PostalState && errors.PostalState}
                          helperText={
                            errors.PostalState &&
                            touched.PostalState &&
                            errors.PostalState
                          }
                          InputLabelProps={{ shrink: true }}
                          InputProps={{ readOnly: true }}
                          required
                          style={{
                            width: "100%",
                          }}
                        ></TextField>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          id="PostalPostcode"
                          name="PostalPostcode"
                          label="Postcode"
                          value={values.PostalPostcode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.PostalPostcode && errors.PostalPostcode
                          }
                          helperText={
                            errors.PostalPostcode &&
                            touched.PostalPostcode &&
                            errors.PostalPostcode
                          }
                          InputLabelProps={{ shrink: true }}
                          required
                          style={{
                            width: "100%",
                          }}
                        ></TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid></Grid>
              )}
              <br />
              <br />
              <Button
                style={
                  window.innerWidth > 775
                    ? {
                        backgroundColor: "#D3212D",
                        color: "#ffffff",
                        marginLeft: "15%",
                        fontSize: 20,
                        width: "35%",
                      }
                    : {
                        backgroundColor: "#D3212D",
                        color: "#ffffff",
                        marginLeft: "30%",
                        fontSize: "110%",
                      }
                }
                onClick={back}
              >
                Back
              </Button>
              <Button
                style={
                  window.innerWidth > 775
                    ? {
                        backgroundColor: "green",
                        color: "#ffffff",
                        marginLeft: "2%",
                        fontSize: 20,
                        width: "35%",
                      }
                    : {
                        backgroundColor: "green",
                        color: "#ffffff",
                        marginLeft: "2%",
                        fontSize: "110%",
                      }
                }
                onClick={next}
              >
                Continue
              </Button>
              <br />
              <br />
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "10%" }}></Grid>
        </form>
      </div>
    </div>
  );
}

const styleHeader = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleHeader1 = {
  fontSize: 30,
  color: "#ffffff",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "0.5%",
  fontWeight: "bold",
  display: "flex",
};

const styleCheckBox = {
  fontSize: 16,
  color: "#ffffff",
  textAlign: "left",
};

export default SchoolContactDetails;
