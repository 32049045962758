import React from 'react';

export const Logo = ({}) => {
  return (
    <svg
      className="logo"
      style={{
        marginLeft: '0',
        alignContent: 'center',
      }}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="45%"
      height="8%"
      min-width="40%"
      min-height="8%"
      viewBox="0 0 1114 380"
      enable-background="new 0 0 1114 380"
      space="preserve"
    >
      <image
        id="image0"
        width="1114"
        height="300"
        x="0"
        y="0"
        href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA1oAAACXCAMAAAA74tpbAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABGlBMVEX///8lKEYlKEYlKEZM
LkeFM0TBMDzYKzjaREPcS0bYKzifXmLjdmfaPEDcT0naOT/YKzjYKzglKEbRLDrYKzjYKzj////n
iHbYKzglKEbYKzjojXslKEYlKEZ9MkXbSEXKLTvYKzjtpJLYKzhbMEfhaVvYKzglKEaxMD/YKzj1
z8PZMjvYKzjuqplrMUfaP0ElKEYlKEYlKEbYKzh7MUXSLDklKEYlKEYlKEbYKzhnMUclKEazMT+L
MkQ0K0fTLDmkMkDKLTtjMEfzw7XeWE/rnIrzxbjaPkDmgnHsoY+/MD3////yva5YL0dzMUWPMkPH
LzvBLzzPLTptRVbCXVnhalzZNz0lKEZQLkfYKzhGLUcxKkc7LEf///+EEvxTAAAAV3RSTlMAIHCA
kKaQgJ6rQJ6miLiPcCCQ98AQEMFgUJDKEODw6PDQlFD11uDA6/BM9qCgvqzwQLAwH/agMGCw9tDo
68T16+6sdOioPfDQs+0g0NDZyNDonuCAwchmP6OKAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEwAA
CxMBAJqcGAAAAAd0SU1FB+UGFwsPFFnEnUQAABpwSURBVHja7V1pY+NKVpUtoCEwkJjOo91YwzQN
EWEasP3AssfsM+z76gnz/v/vwNrrbrVIZdmd3POlO3JVqSTdU/fWqS1JFAqFQqFQKBQKhUKhUCgU
CoVCoVAoFIpXiNk8/bmf/4V3Jn7xl97doVS//CvfA0l+9dfuH65dc4XilrE4lfjpr78gvH+E6b75
DZzi5cO1665Q3C6Wpxr/R4jzESf9TZJE/ZZCIWCVNdQ6fZ8QB8eE3/wW8WzXrr5CcatIW2adfvYD
TJxPOPFvE/Z9vnb9FYrbxOzU43cIce5x8t/FKZ4eB9xUoXj9yA1qnX4PE+cZE+f3f4iTvLv2EygU
t4ilyazTd8RtfcEZqEqoSoZCQdBrGKpkKBQRkUJm+SgZf6BKhkLhwuyE8YduJeOPVMlQKBzIMbPW
s/dOJePxWZUMhcKKJXFaafLgVjLuVclQKGzAGsbplJ2vvnMqGcknVTIUCgtS4rQ2CRfvESXjTpUM
hUIG1TC21fUPbiXjiyoZCoUIRsOof1AlQ6EYAU7DqPGtKhkKxWDwGkaNj6pkKBRDwWsYNVTJUCiG
QtIwaqiSoVAMhKhh1FAlQ6EYBFnDqKFKhkIxBDYNo4YqGQrFANg0jBp//CeTKBk5wCYw9w7kLvhE
s02aprkv0nS+WfEFFSDhdpV4oxCquTev75uLC++6UnCvoFimqXeZ2zTdS19h71sIwi5NN6C3kbwH
+Ji4cGcmPziTPzrLfwAJYvZk7BpG/YF/NImSAWuxC8u8sbcOZ6z22/UpHNlu6b7d0Z9bIKdRTdDC
tRF57qyeDPwKZvNBpR0XHEfTIUX1r9So2wGajdNqoKHduZIjEe6Dq8CXb8PszgbyvqGGcUZxOv3p
FEoGqscsKDN6DEKt1W4Ir5o3kpLbIWqdcu+KXolaxYiiMtq4jKJW+b662rltH9qZo9eP4WQujrfi
9WRcGkb9femOnxdQMlA9gtwW9r2YWsvhxKqMC7fcmFr+tb0OtUZSIcdeeSy1TqdFUxJqk10hHmLi
syO5m4lE/3Y6Qk+4NYxkX179M0Ic4jhHKxm4JiFua2ezq+YZxmC9hwUSanlz6yrU2o0op7YK1LaM
p1bn6FFA5ogIkRdydenvXak/E8N+CrNaEW4NY1W19z+jm8DjokYrGbgmqX/Wmf0pRjPrzC1oW5Ra
vty6BrVGM4v0JiNQq31hyG7sESFZnOuICGF7z7DmiVhtpLMLZiROohpGff1H7hqMVTKINftrA8R2
ALVm46LBGhmoDUMtT25dgVrLEaV0gIYRg1qnpgsHrdseEZJux7PVxhATqT5IpxrFmkq0xU/LaRg1
Lq9kkFfv7bZWhDuAWmMMVKgNR63T3Keu01NrFaNlidt3q9H0PdCwqdWwSa/D7mMQE0nvn5psuNl6
fGZqP+DjepxdMlLJIJXxdlv2sJYOLwwCqA1LrdPeo67TUytCOFwCiKBRqNW8L+RabFyhE4PsXg4y
kYzGMhtUuD2hHzw1jBoXVzKGmWrCNssmtRZxTAvUZuNOImB6ah0jPb8Z0MShVs6ZjY0rHxkiWHjg
Ii3vtLjAMRS+GkaNiysZ9NVnfhmZ72w+SHaKA7O3IVDLg1uTUyuS04bxbhxqnepAAHV4LFxhRAeb
l7t3FPzlRcDYGbD+GkaNSysZzKv3c1sMdwybjWZaa+OWErXcNZ6cWpHiQWgdkahVCxmPDqvqcMfR
wBIZQXdIxMSHFwnuCVR2BGgYNS6sZDCv3sttcbZj2GwUfayCob+L1FoXjtpOTq1Y8TBoWiJRq3nO
g6dd815GdDGIOiSCOryIGDduHKJh1LiwkmGzDxsyez5sBdkuXW7c2KdkyqEx30eklpNbQdQq+LrN
zbRH4QFa1QV9xNNxkXo8/mae4oynlVDbPPUFbs2bzhYatxUjnWeWBvdScihSkEEt6AOhhxs33SlI
w6hxWSWDM1SPuXlswCOOl649pZEKiJVG0yNTy8WtIGr5lOF6RfA7HwNWFKwQEwbW1lLksbkMOSNF
hHTmRE0K6WawZ0bcAJzidAdd4pgtKMI0jBp/TpqNmEoGa6huSzg6suXgB1e8BgHcQzftLbFSy8Gt
yakF6pYFLH9JcJ/B8NpDqYVn1jVXofInRYRSACcERt/aU0Hx5H3yAIx7xHSnUA2jtks6eB1RyWDt
dOvKxRu5RK3ApSqwyc9dd/Xh1nWpFeKzEywBGbUaTC3UWjUXEQt4i3l8EXDP3wnylfg26NMecPw4
fLpTsIZxqlrtS+6Twdupa5JuzuYybBa0IcskDLBP0V+3Usu6fGtqasGahjkt9HajUAuaVft1nzzM
GrTrppUJHsZeJlQFSvUQWu7gceNwDaOejkCHw+MpGbyZOvyMYOMbodhQ05KMGFyfkwjAwq2rUuuY
BEKq1XBqwe/RvgBoNHxEaDbrT6DfxS5ftHtCjkewEkOnOw3QMOpggo6HR1MyeJI43JYwqVuiVuh7
kgwTEoTq+zK3rkot/xWbjlqNoNaRewEPVh7QJB+B8sHOnoCWige1YPTX0Aj4uedh48ZDNIz6s9B4
L5qSIVBrYcsjjQcL1FoHvymBmIggtB0SuXVVajl7rr53GkGtnH0BsKfBRYTApzyAPj0bET7ZTBBq
Fm3wB6UE5wpmDsM0DO727Hs44BR+SobAEuskXWklkkCt4Fbbk1oct4QCr0qtMBIkE1ILmhUXEZrB
0CfUgDMRIYwHMfmg2NZZMCT4ELc1UMPgbv/CdPhgk2A4XDsElti+nDiJaWpqMdwSOolKLfYFoGiI
tsWAKvcJ9EqMg4Hx4EdLYQbvYMA1YNx4qIbBVYupNzdX36cBMO6W8bcmAL7VdMWTU8ubW0ot/gUI
fqQDoMojusBskfFssz/oHj5LP4RPdxqsYTAPyftjMkPZpwEwP+JOuDcE6BDm5jebnlq+3FJq8S8A
TrWgEeET/hXIGqQ3D4tDg1qyc3oItlqA4RpGjUspGebtQKQnTtIFT7K/MrWYfh/HLaWW8AJgc4wj
wjtqTGbni0SEVicINew773wujNEwalxIyQAf0cdtgRYgA9/sGtRiuMUYnVJLeAEfrRZlmnwT/t3T
Sz1g8w+NzyaZQL8RON1piIaBmt/LKBngI4I6CG4LhGD761OL4RZtFJRaZlajG/1gMXhIlS9MBkRF
GA8in0amOJmAOsH94JfFv5kcp8BCwhAlwz0CBz+i1LgZMPuMWXID1PLh1punFujpmz/AMM3iaNqu
vRkaISraprFD08Sh5IjpTkM0DLJV0RAlw7lyE35Et/kgp3UL1PLg1lunFhwvMX+B84ygGzJZ1EVp
gG+QA4AfMKxzcQeGiwHTnYZoGHT0c5CS4ZIy0UfMbZUsgZzWTVCLhtsn2767b5BaqVh3OLP9IP50
z14FVITxIAyp2ClOJqzxooxBGgZj14OUDEfd0Ct3zdABE/dK+70Jaq3o8jHLvrtvj1oFMED4WaHF
mO7kA2/sZtgHqAjjQcAO2KXj1DVrP01GBA2jxgWUDPwRTaowk3TNnzN84WrUYrgFl2+9aWqtUti0
w5zQps222uyGHYQMBoFgUAUHtTzUdWjbnqcCxdAwalxAycAfEVSW8Bsu68A1vx61XNx6hdTKfA+u
I/18tGYUMMKgkKgFmhnu+8swpgL0gf0UPpAaNN0pioZRI76SQT4iqC12WyaRavbfCLUc3HqF1BqM
Nbob9Ch9GARGsMzoyDRCwzkdhHISz5lMB59EEHE0jBrxlQzyEQHNkdsqqMXcCrXs3FJq9cDrhWAo
1HsbcSYuyNDFRFAPARn8HBLsj4nb2hiIpWHUiK5k0I9oui0Ul+7oTzdDLa6BWrlyvkVqkQ40iHO6
GAcQ6E7McN9ehJb5WbyB3NLbZ4YwiKZh1IitZNCPCNwWMIsZ88vtUAvpYIBbSi1LbWD3vDUn09Cf
5AzdT6BFBxl81zqi6U7OceN4GkaN2EoG8xFFt8U4rVuiloVbSq3ujdDbwUis9RZPsomBDE1n/1G0
yUfvISvn4BdERA2jRmQlg/mIe6EuK85ebolaMreUWvB9QLxnbOWzjQ6mLN+wCLomMwMkzPs7GZ+B
23JNd4qpYdSIrGRwHxHPuGCeZc3twHx1aoncUmrV2LLxEKRFbdGmbvjJlqGJ/Q4vQgbpzB83HKcC
WddgVZiT53ds20f3RSNui3TIZL2FqxqoUjdlCLQBXW/wtqjFcGtny/m2qJUJlgWDuToifCZXpAzf
SkXUEM/8ccN7RFZ4z3RuqePFU59EolK6lt+ngt1HREuyuA/bSTE3Ri3miJRdotQqv+NO3m4cmH8V
EVom4ZYwfVSlS8D23sggn/njhn26E3lCorzT9+XYIZ3q72QxMWkpwrwWrFPb1LFO6+aoxS/pf8PU
KidkpOnGurEknOxUEsPkDtNTN22wWhAJ1qaYlDi8jIB1RNaxdDjhxr3sn+2RiBRk3IqqiPIgAXtf
uP1FfQ2YbP+hbo5aLLdeIbW4UsmwufPgsQZPyFhAfMdtA/EMf4e+ycjAnnvnDet0p5WbOLQtsna2
aLBHuP0+oIoeH7E2RVPbMMbdPKgVvHuutEGoH7UYbi2+ri0+QVBrvGt3bemUFL+jHIDsfGC8EoYZ
F31B8aDZ1BNTjOi2rNs01aD6vGVci8auxF/TiNEyj5g3F+q2BKd1EWp57fluOaiI8VvXpNaUG1Ov
yAwFL27BOOeRU9chzBDyGcWDH/lkQ2BfEpXjZyUDwh6jyj0O+O5kPJiqnTYVUzABolkITsuLWq5z
TzBAqz2AWo6ThG/+OIWFUCuv2hJZzOsIJsCND9yYMMKTmN4U9rgTxoNgne5UkE9L9lOni2RFY/SQ
B8mYsnXsTTCXGfo4G6l2ArWAJw49BAiY1hBq2Y8SnoBacMF86EktR6FWfrUdxC0Q0R3MvwQF7KOU
3sxA46dQ2MeNoyoZcTWMRKQW/EAzwCDQdxColXs9DQ/YPx1ELXFb+omoBb12oI6xkTJ71pY8+9HN
bTguxc6/hQBm9ok3t+GjxRbPYSKmkhFZw0hkakG3JRu1D7XsM7ccpiGdZbwJKeS61FoHHGVMpIgB
tSWNuQe3DpJtS+O2n6QMvZ9B1vrhzguwJna3FU/JoJPax2kYiUwtOBc3F5MJ1EKNRcA52UvIyqHU
snBrCmqhh9h6dzdXe2QLRojjXVticW4RXordxOmn90KGflALWatzc7E2m1Sex3sermSQpmWkhpFY
qOVzHkkiUotM31p7Lje33S6IWjK3pqAW6T57rren++cYhfrXdgC3iOE0rkZKL02z6Ae10MQF712a
QvIV5H0NUzKiaxiJhVqSaaJUArXsxw6HYCUU6vaDHseAXYxacVYtnqC8GFBbj0gJgZ/r9yxn4Ies
+r5/kPcxEeTtIikZ0TWMxEYtwW0hk86Fn2KZlrkGJ5BazBZqOOfFqBWtaTEb4ZDaBnOLmo6DEHwI
2QdJkHohJ6miPpp13DiOkhFfw0hs1GI8KZMoF6ydzTzStAKpJXFrCmrRJQ8DYVpB0GZpdA3Awp6B
HYOymPUjl74P31CIFXL+N+rV2G04hpJxAQ0jsVKLbXmxRefCb/ZRW3/4bHkmgufWJNSyqf8BWJtl
hu1DKKyvEUFbbsdsCC6E7Ae1kNMK2cadSIvWxDl5Z8FKxgE/xngNI7FSi1aaWSNtpgEb2sZptkGd
gqnFc2sSakWKCEGdArf4pNzKbSI8p0tYfQ03i6ljwYeAgiigB7VPdyrISwtVMi6hYSR2ajHmQZxt
Lll7nI78RqyPn5zPcWsSanHtUjhgAxy6e65lHx4OzEiVvYP0TDN0BhdCDgpEzHtr4tFKxiU0jMRO
LWoemTUJtHZBRAgCbH8GUCtZkUB7ImrNYrht2N8O3pi6IN8gs4jwtDvh2AyQRoSd6hEU0jGA1m53
EWOVjItoGImDWsRtUZUpF609gm2hRnYItZimexpqMeudg4E6R+F7vrv2wgeguoSDEbQtbwe1UNck
1GkFiiDjlIzLaBiJg1rYbWX2FMjai7F+C3cNBlGLcmsiaiXLsW0L7jMMOE6B4ZYswhMv5OpP4Eiq
o1CIfM4jSLrPyUMGKBkH/NRRNIzERS3UHjBfxUKtZGWdgO7Cmsw8HEYtwq2pqJXM8tMIZOQJh5xU
wnQ2RW5hXcI5yvtOsDikiHiejQBwF1KVgjyjv5JxIQ0jcVELOlLudGMbtc7GtRvacmcp7XEPpBbm
1mTUOucZPLx3ZAgw6BCgVU7KFge4UOv82VU2FhXb5h65vwFOi3Rv7G5rhJJxIQ0jcVILn69KYKfW
GZuUHkPjMqvtnO0RDKUW4taE1Dpb9nKRhzYv+W7PTucdeL4WHWKTBrhg8/zsLhqKiq3qgWzRd14u
RJDnG65kXErDOGPTo3D8zm+qafwuK7urjT8Sv1KC1hfOhJzgumN2Ori753Yu7H3sKDxLCVi6TW8i
vLxHsLrDo6v+ADI8sFfvgkaLe3wLS7EnHqpkXEzDUCheCXJMHD8l40C6UZE0DIXilaAgxPFRMi6n
YSgUrwWDlIzLaRgKxWvBECXj+5fTMBSKV4NwJeO7v1ANQ6FwI1jJ+EvVMBQKD4QqGT9VDUOh8EKg
kvEDp4ZBl7CphqF4iwhTMlTDUCh8EaJkeGgYdBm1ahiKN4oAJcNDwyCjXqphKN4q6GQmScnw0DDI
zF3VMBRvF3SWv6BkqIahUITAV8lQDUOhCAM5bIBVMlTDUChCQTcDYZQM1TAUilD4KBmqYSgU4fBQ
Mv5KNQyFIhhuJeOC+2EoFK8YLiXDYz+Mt6dhpGnYidt7r/TnVEGHDStuHA4l46AaBsUJnD/qRu6V
Pg/ZeUxx+7ArGR77YdyYhrHKc9eZnqMRkVr7PDdSKbVeFaxKhns/jFvTMDYTGGhEai36nzQgfG2w
KRlUwyCbBN+ahvGVUcsvVlR8nZCVDKphEN7cnIah1FLcDkQlg2gYLxfUMFbngKhEFRQV5cbIm/LP
akfsovxt3ukryzpltyP5qtwfeXZOM9tszhHWrt9XupiXGdt0bako7toYN57X/1+295qVuyeXpdQF
lL/NC0qtos7Wkno276peouVP0W3FvKp2fj4Xfn737TbQs36XZ5S/fxt9N3LW1DRgY2jF5JCUjEk1
jH0bl1bmmZ+ydmuO42aRIW+ad/VctQ+QV9eMJymvd6dj7FZNvmN1ZAlwa5vMvHHXujRH/6TnzFlT
nnlQF6h7fwpH9Wd36E5bSEutvDtDYVOF3f0y7ry+VSrl797Guimg239Bu2c3DUHJoBoG5k08DaM8
u3Cdn9FSi0XTjudl0oqK2+rvjlGQWuWZTtkuLa3ymJilmtTqF1s31Mpqlp5qs0778qrC1/mRUqvM
cDzXPasuVytxmnS79vf2Xw9qFUb+RYLfxnrWVutc0XMipdZNg1cyJtUwtnAeyNmYjuUJPKvShta7
Mj4rY6ejmWW2aNuA0uiPi9LINptdHxDuGtOeHTtfWJYFQqiSB8eascZ4XlGmrP5X3r8+C6h8ScfK
kJeIWrMTGGjPm+PeZrvWqUjUggFh2teyus9sa+Sv3kbl2qtHWltOp1LcEjglY1oNI4OzF3sj3HZO
ZoadRffbxuBlaqZvDrnbNP/Lafy04I+DL3m0gcXN+4NnUUU2oFkoTt1Jf9vGrUrUSoCM0dzKkr+q
xLGuQRZ0+pDiamCUjEk1jJJapjfpjXDZt89HzIx904bz1Jr3Oc+lrxKWWhk+Xr7BrnFEfXHH3jVR
am37v9L+SLaiSRhELUv+M+WbK/zRmIobBFUy/tqtYXwvmoZRNdDZvm+IGSNkmNH+xlNr0bOmyUmp
tToJ4ndbTF/cqaZn818zbelOtx1Bt203Lanitua+0lNRauVy/v7WVRirfuurAFEy6H4YmDc/jqZh
JC23j4smKrRTa7OtRLv10UotKIXw1Nrg5n9fyyNZhqm1aXtfCR3Xql7eels3DeZN8mHUkvL3t64j
+Hyu0vvtAysZdD8MrGF885NoGkaJVgPfIvtkqGXuoDieWmAVjdnCEGp1KYmrS+u3t94nE1Er2Tev
S8WM2wdUMuh+GIQ3fxNPw6hRzKvBm6qjYaNWHX+VutrcRa05PDaXpZapOpakXVTJtyFeqzqF+9gQ
eBpqVeeel3ecJ4pbB1AyyH4YRMP427+Lp2F0qLT28j82as071cDe19oRu6PUQqpj308ifS2zV8Z3
0EqNf2dKmpVI4qZW22Vi+lqZhVpJLe8fE8Wtw1QyPPbD+PuIGoYBIjhQaqWdPMFRa9kJbHMg3CUJ
qxCugULYGzorY7QpBe1j1o4Db/sLHgphW6VeIZTy01uvdQri1wCjnzGxhtFjtfaiVgp/M6nVh3il
dA13+mCotQXtft7loNQyUgrU2lfVMG6bNvVqqbHr+kYmtVpJ3xjX6vOjcS106+Kk1Poa0CsZk2sY
HRZNj8ZGrbMFryvbK3YMtcrAbd+lX9f/3cxTWGqH0ldvS52tmC8q268HkJc5oda+Sbla7niDXjVT
JPJ2pt+8dXQtNfZN32g2z9sap/2QtTEbo88PZnMkmFpHZldWxQ2iVTI8NIx/iK1h5KdyAuG61bys
CmEpjjWJKbUqYTBblymbyXhVwhyW2qPy1dmxTjKrM+SMQthsgN82P2YRm2Y636ke9q5um9W3hXMI
V5lRRF3jWfXf6ncwh5DNn3TUauZbnoQBb8WtoQ4Jv/tHTJtPKBz8p3/+YexwsFPKuwmpMrWKdq76
lqPWLOvE9qI/jxkR1kA3h7w043b+/Zp6rWTVzj4viWiW0PVSGx/Zz1zvtcr6P12FOq/VjiSYtyrE
/D21WuiS/68E+92//Ou//ft7gP/4z8+QWf/13//zvzDJ+3d3A2/Y3zlNz81wt7QKr2yCF1f7Rb4r
11Q1vxlpyr/mu23azOxYpttzyn2BSzUxm+/OaZpfyj8W6aZeApaARVTlqqxtXq4Sm20AQ2dpusvz
7p5JFV2eH6b7u+jTn2u+LWte9E+VVjcEtypSKX+zEi0tE2z7FWsKhUKhUCgUCoVCoVAoFAqFQqFQ
KBQKhUKhUCgUCsVXjP8HriICpIlA3aUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDYtMjNUMTE6
MTU6MjArMDM6MDBMMqnwAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTA2LTIzVDExOjE1OjIwKzAz
OjAwPW8RTAAAAABJRU5ErkJggg=="
      />
    </svg>
  );
};

export default Logo;
