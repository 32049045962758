import React, { createContext, useState } from "react";
import Axios from "axios";

export const DatabaseContext = createContext({});

export const DatabaseProvider = ({ children }) => {
  const [state] = useState({
    //apiURL: 'http://localhost:3010/api',
    //IDCaptureAPI: "http://localhost:3020",
    IDCaptureAPI: "https://office.monitorwa.com.au/idcaptureAPI",
    imageURL:
      "https://office.monitorwa.com.au/idcaptureapi/assets/images/accessories",
  });

  return (
    <DatabaseContext.Provider value={state}>
      {children}
    </DatabaseContext.Provider>
  );
};
